<template>
  <v-main>

    <v-container
        id="review-claims-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-account-multiple-outline"
          class="px-5 py-5"
      >
        <v-card-text v-if="errors.length">
          <v-alert color="error"
                   dark
                   outlined
                   :key="error.id"
                   show variant="danger"
                   v-for="error in errors">
            {{ error }}
          </v-alert>
        </v-card-text>

        <v-card-text>

          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-subtitle-2 incident-date">Incident Date</th>
                <th class="text-subtitle-2 member-name">Member Name</th>
                <th class="text-subtitle-2 dependent-name">Dependent Name</th>
                <th class="text-subtitle-2 dependent-location">Location</th>
                <th class="text-subtitle-2 relationship-type">Relationship</th>
                <th class="text-subtitle-2 cause-of-death">Cause of Death</th>
                <th class="text-subtitle-2 claim-action">Action</th>
              </tr>
              </thead>

              <tbody>
              <tr
                  v-for="claim in claims"
                  :key="claim.id"
                  :class="claim.status === 0 ? 'highlight-new-claim' : ''"
              >
                <td> {{ claim.incidentDate | formatDate }} </td>
                <td> {{ claim.user.names }} </td>
                <td> {{ claim.dependent.names }} </td>
                <td> {{ claim.dependent.city }}, {{ claim.dependent.country }} </td>
                <td> {{ claim.dependent.relationType.name }} </td>
                <td> {{ claim.cause }} </td>
                <td>
                  <router-link :to="{ name: 'edit_claim', params: {memberId: claim.user.id, claimId: claim.id} }">
                    Review
                  </router-link>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>

      </base-material-card>


    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import constants from '@/utils/constants'

export default {
  name: 'DashboardReviewClaimsView',

  created() {
    this.fetchReviewClaims()
  },

  methods: {
    fetchReviewClaims() {
      api.fetchReviewClaims()
          .then(response => {
            this.claims = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    handleError(err) {
      let errorMessage = api.parseResponseError(err, true)
      this.errors.push(errorMessage)
    }
  },

  data() {
    return {
      claims: [],
      errors: [],
      claimStatusMap: constants.getClaimStatusMap()
    }
  }
}
</script>


<style lang="sass">
th.incident-date
  width: 10%

th.relationship-type, th.claim-action
  width: 5%

tr.highlight-new-claim
  background-color: #F1F8E9

</style>